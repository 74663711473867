import Cookies from "js-cookie";

export function setCookie(cookieName, cookieData) {
  Cookies.set(cookieName, JSON.stringify(cookieData), { expires: 2 });
}

export function getCookie(cookieName) {
  if (Cookies.get(cookieName)) {
    return JSON.parse(Cookies.get(cookieName));
  }
  return null;
}

export function removeCookie(cookieName) {
  Cookies.remove(cookieName);
}