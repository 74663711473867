import React from "react";
import classes from "./ErrorHandler.module.css";

const ErrorHandler = ({ icon, type, message }) => {
  const navigateHandler = () => {
    window.location.href = "/login";
  };
  return (
    <div className={classes.container}>
      <div className={classes.sub_container}>
        <div className={classes.content_container}>
          <div className={classes.center_icon}>
            <div className={classes.icon_container}>
              <i className={`${icon} ${classes.icon}`}></i>
            </div>
          </div>
          <div className={classes.message_container}>
            {type === "error" && (
              <p className={classes.message}>Some Error Occured!</p>
            )}
            <p className={classes.message}>{message}</p>
            <button
              className={classes.back_btn}
              onClick={() => navigateHandler()}
            >
              Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorHandler;
