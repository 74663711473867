import { createSlice } from "@reduxjs/toolkit";
import { getCookie, removeCookie, setCookie } from "../Cookie/Cookie";

const initialState = {
  user: getCookie("user") ? getCookie("user") : null,
};

const userSlice = createSlice({
  name: "userSlice",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      setCookie("user", action.payload);
      return state;
    },
    logoutUser: (state, action) => {
      state.user = null;
      removeCookie("user");
      return state;
    },
  },
});

export const { setUser, logoutUser } = userSlice.actions;
export default userSlice;