import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import sidebarSlice from "./slices/sidebarSlice";
import drawerSlice from "./slices/drawerSlice";
import dataSlice from "./slices/dataSlice";

const store = configureStore({
  reducer: {
    userSlice: userSlice.reducer,
    sidebarSlice: sidebarSlice.reducer,
    drawerSlice: drawerSlice.reducer,
    dataSlice: dataSlice.reducer,
  },
});

export default store;
