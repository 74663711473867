import React, { useState } from "react";
import classes from "./LoginPage2.module.css";
import DotLoader from "../Components/Loaders/DotLoader";
import Loader from "../Components/Loaders/Loader";
import { toast } from "react-toastify";
import { SignInApi } from "../Api/api";
import { useDispatch } from "react-redux";
import { setUser } from "../slices/userSlice";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";

const LoginPage2 = () => {
  const [msisdn, setMsisdn] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signinHandler = async (e) => {
    e.preventDefault();

    if (!msisdn || msisdn == "" || msisdn.trim().length <= 0) {
      toast.error("Please Fill the Sign in Details!");
      return;
    }
    // if (msisdn == "123" || msisdn == "1234") {
    //   toast.error("Number and Password is incorrect!");
    //   return;
    // }
    
    try {
      setLoading(true);
      const response = await SignInApi({ msisdn, password });
      setLoading(false);
      dispatch(setUser(response?.data?.result));
      navigate("/");
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.err ||
          error?.data?.err ||
          error?.message ||
          error
      );
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.image_container}>
        <img
          src="/assets/images/mobile.webp"
          alt="mobile"
          className={classes.image}
        />
        <div className={classes.chat_container}>
          <div className={classes.chat_sub_container}>
            <div className={classes.header}>
              <p className={classes.number}>56765</p>
              <p className={classes.lang}>
                <i className={`fa-solid fa-globe ${classes.lang_icon}`}></i>
                English
              </p>
            </div>
            <form
              className={classes.chat_input_output_container}
              onSubmit={signinHandler}
            >
              <InputText
                type="text"
                keyfilter="int"
                className={`p-inputtext-sm ${classes.input}`}
                placeholder="Enter Your Mobile Number"
                onChange={(e) => setMsisdn(e.target.value)}
                value={msisdn}
              />
              <InputText
                type="password"
                className={`p-inputtext-sm ${classes.input}`}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <button className={classes.btn} type="submit" disabled={loading}>
                {loading ? <Loader /> : "Sign In"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage2;
