import React from "react";
import { useRouteError } from "react-router-dom";
import ErrorHandler from "../Components/ErrorHandler";

const ErrorBoundary = () => {
  const error = useRouteError();
  return (
    <ErrorHandler
      icon="pi pi-times"
      type="error"
      //   message={error?.message || "Unknown Error"}
      message="Login Again, Token Expired!"
    />
  );
};

export default ErrorBoundary;
