import React from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import HomePage from "../Pages/HomePage";
import HomePage2 from "../Pages/HomePage2";
import Auth from "../Components/Auth/Auth";
import LoginPage2 from "../Pages/LoginPage2";
import ErrorBoundary from "../Pages/ErrorBoundary";

const Routing = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        // <ErrorBoundary />
        <Auth>
          <HomePage2 />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/login",
      element: <LoginPage2 />,
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);
  return <RouterProvider router={router} />;
};

export default Routing;
