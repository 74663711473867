import React from 'react'
import classes from "./Loader.module.css";

const Loader = () => {
  return (
    <span className={classes.spinner}>

    </span>
  )
}

export default Loader