import React, { useEffect, useRef, useState } from "react";
import classes from "./HomePage2.module.css";
import DotLoader from "../Components/Loaders/DotLoader";
import { toast } from "react-toastify";
import { ChatApi } from "../Api/api";
import { useSelector } from "react-redux";

const HomePage2 = () => {
  const chatRef = useRef(null);
  const [aiChatLoading, setAiChatLoading] = useState(false);
  const [message, setMessage] = useState("");

  const { user } = useSelector((state) => state?.userSlice);

  useEffect(() => {
    if (chatRef?.current) {
      chatRef?.current.scrollTo({
        top: chatRef?.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [chatRef, aiChatLoading]);

  const [messages, setMessages] = useState([]);

  const handleMessageSubmit = async (e) => {
    e.preventDefault();
    if (message.length == 0 || message.trim().length == 0) {
      toast.error("Please write the message!");
      return;
    }
    const wordCount = message.trim().split(/\s+/).length;

    if (wordCount > 100) {
      toast.error("Message cannot be greater than 100 words");
      return;
    }

    setAiChatLoading(true);
    setMessages((prevValue) => {
      return [
        ...prevValue,
        {
          request: JSON.stringify(message),
          response: "",
        },
      ];
    });

    setMessage("");

    try {
      const response = await ChatApi(
        {
          messages,
          message,
        },
        user?.token
      );
      setMessages(response?.data?.result);
      setAiChatLoading(false);
    } catch (error) {
      setAiChatLoading(false);
      toast.error(
        error?.response?.data?.err ||
          error?.data?.err ||
          error?.message ||
          error
      );
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleMessageSubmit(e);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.image_container}>
        <img
          src="/assets/images/mobile.webp"
          alt="mobile"
          className={classes.image}
        />
        <div className={classes.chat_container}>
          <div className={classes.chat_sub_container}>
            <div className={classes.header}>
              <p className={classes.number}>56765</p>
              <p className={classes.lang}>
                <i className={`fa-solid fa-globe ${classes.lang_icon}`}></i>
                English
              </p>
            </div>
            <div className={classes.chat_input_output_container} ref={chatRef}>
              {messages?.map((messageData, idx) => {
                const isLastMessage = idx == messages.length - 1;
                return (
                  <React.Fragment key={idx}>
                    <div className={classes.chat_container_chat_box}>
                      <div className={classes.chat_container_chat}>
                        <p className={classes.chat_container_chat_text}>
                          {JSON.parse(messageData?.request)}
                        </p>
                      </div>
                    </div>

                    <div className={classes.chat_container_chat_box}>
                      <div className={classes.chat_container_chat}>
                        {isLastMessage && aiChatLoading ? (
                          <p className={classes.chat_container_chat_text}>
                            <DotLoader />
                          </p>
                        ) : (
                          <p
                            className={classes.chat_container_chat_text}
                            dangerouslySetInnerHTML={{
                              __html: JSON.parse(messageData?.response).replace(
                                /\n/g,
                                "<br>"
                              ),
                            }}
                          ></p>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            <form
              className={classes.message_wrapper}
              onSubmit={handleMessageSubmit}
            >
              <textarea
                type="text"
                placeholder="Type your message..."
                className={classes.message_box}
                // onChange={(e) => setMessage(e.target.value)}
                onChange={(e) => handleMessageChange(e)}
                onKeyDown={handleKeyDown} // Detect Enter key
                value={message}
                autoFocus
              />
              <button
                className={`${classes.arrow_wrapper} ${
                  (message != "" ||
                    message.length != 0 ||
                    message.trim().length != 0) &&
                  classes.active
                }`}
                onClick={(e) => handleMessageSubmit(e)}
                // type="button"
                type="submit"
                disabled={
                  message == "" ||
                  message.length <= 0 ||
                  message.trim().length <= 0
                }
              >
                <i
                  className={`pi pi-arrow-up ${classes.arrow_up_icon}`}
                ></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage2;
