import moment from "moment-timezone";

export const setLocalStorageData = (key, data) => {
  localStorage.setItem(key, JSON.stringify({ ...data, date: moment() }));
};

export const getLocalStorageData = (key) => {
  const storedData = JSON.parse(localStorage.getItem(key));

  if (storedData != null) {
    const dataSetDate = storedData.date;
    const currentDate = moment();

    if (moment(dataSetDate).isSame(currentDate, "day")) {
      console.log("Send back the data...");
      return storedData;
    } else {
      console.log("Expire the LocalStorage....");
      removeLocalStorageData(key);
      return null;
    }
  } else {
    return null;
  }
};

export const removeLocalStorageData = (key) => {
  localStorage.removeItem(key);
  return null;
};