import { createSlice } from "@reduxjs/toolkit";
import {
  getLocalStorageData,
  removeLocalStorageData,
  setLocalStorageData,
} from "../LocalStorage/LocalStorageUtils";

const initialState = {
  data: getLocalStorageData("data") ? getLocalStorageData("data") : null,
};

const dataSlice = createSlice({
  name: "dataSlice",
  initialState: initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
      setLocalStorageData("data", action.payload);
      return state;
    },
    removeData: (state, action) => {
      state.data = null;
      removeLocalStorageData("data");
      return state;
    },
  },
});

export const { setData, removeData } = dataSlice.actions;
export default dataSlice;
