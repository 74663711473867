import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("sidebar")
  ? JSON.parse(localStorage.getItem("sidebar"))
  : {
      expand: true,
    };

const sidebarSlice = createSlice({
  name: "sidebarSlice",
  initialState: initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.expand = !state.expand;
      localStorage.setItem("sidebar", JSON.stringify(state));
      return state;
    },
  },
});

export const { toggleSidebar } = sidebarSlice.actions;
export default sidebarSlice;
