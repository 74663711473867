import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("drawer")
  ? JSON.parse(localStorage.getItem("drawer"))
  : {
      drawer: false,
    };

const drawerSlice = createSlice({
  name: "drawerSlice",
  initialState: initialState,
  reducers: {
    openDrawer: (state, action) => {
      state.drawer = true;
      localStorage.setItem("drawer", JSON.stringify(state));
      return state;
    },
    closeDrawer: (state, action) => {
      state.drawer = false;
      localStorage.setItem("drawer", JSON.stringify(state));
      return state;
    },
  },
});

export const { openDrawer, closeDrawer } = drawerSlice.actions;
export default drawerSlice;